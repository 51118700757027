import { createRequestUrl } from '../../../../play-core/utils/request-url-helper';
export const PacLandingPageApiPath = '/landing-page';
export const loadPacLandingPage = async (axios, preview, previewDate) => {
  const requestUrl = createRequestUrl(PacLandingPageApiPath, {
    preview,
    previewDate
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};