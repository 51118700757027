import { useQuery } from '@tanstack/react-query';
import { loadPacLandingPage } from '../../api/pac/load-pac-landing-page';
import { loadPacPage } from '../../api/pac/load-pac-page-id';
import { queryKeys } from '../../config/use-query';
import { useVideoConfig } from '../../config/VideoAppContext';
import { useAxios } from '../../hooks/use-axios';
export const useHomeSections = (initialData, pageId) => {
  const {
    usePreview,
    previewDate
  } = useVideoConfig();
  const axios = useAxios();
  const {
    data: pageById,
    isLoading: isLoadingPageIdSections
  } = useQuery({
    queryKey: [queryKeys.PacPageSections(pageId ?? ''), {
      usePreview,
      previewDate
    }, axios],
    queryFn: () => loadPacPage(axios, pageId ?? '', usePreview, previewDate),
    enabled: !!pageId
  });
  const {
    data: landingPage = initialData.pacPageConfigs?.landingPage,
    isLoading: isLoadingVideoPage
  } = useQuery({
    queryKey: [queryKeys.PacLandingPage, {
      usePreview,
      previewDate
    }, axios],
    queryFn: () => loadPacLandingPage(axios, usePreview, previewDate),
    enabled: !pageId && !initialData.pacPageConfigs?.landingPage.sections.length
  });
  const isLoading = isLoadingPageIdSections || isLoadingVideoPage;
  let homeSections = [];
  if (pageId && pageById) {
    homeSections = pageById.sections;
  } else if (landingPage?.sections) {
    homeSections = landingPage.sections;
  }
  return {
    homeSections,
    isLoading
  };
};